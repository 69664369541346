import { Component } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../auth/auth.service';
import { UtilsService } from '../../../core/services/utils.service';
import { Router } from '@angular/router';
import { faHeart, faListCheck, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../core/services/toast.service';
import { CartService } from '../../../core/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  currentUrl: string = this.router.url;
  isMobileView = this.utils.isMobileView();
  isNavbarCollapsed = true;
  isSearchBarCollapsed = true;
  showCart = false;
  profile: any;
  searchForm!: FormGroup;

  isAuthenticated = false;

  icons = { faRightFromBracket, faListCheck, faUser, faHeart };
  searchQuery: string = '';
  cartQuantity = 0;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private utils: UtilsService,
    private router: Router,
    private toast: ToastService,
    private cart: CartService,
  ) { }

  ngOnInit() {
    this.searchForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
    this.auth.isAuthenticated$.subscribe({
      next: (isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.getProfile();
        }
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });
    this.cart.cartQuantity$.subscribe({
      next: (quantity: number) => {
        this.cartQuantity = quantity;
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    });
  }

  getProfile() {
    this.api.getProfile().subscribe({
      next: (response: any) => {
        this.profile = response.data;
        if (!this.profile.isPhoneVerified) {
          this.router.navigate(['/profile/verify-otp'], {
            state: { ... this.profile }
          });
        }
      },
      error: (error: any) => {
        console.error('Error in header component:', error);
      }
    })
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
    this.isSearchBarCollapsed = true;
  }

  toggleSearchBar() {
    this.isSearchBarCollapsed = !this.isSearchBarCollapsed;
    this.isNavbarCollapsed = true;
  }

  openCart() {
    this.showCart = !this.showCart;
    this.router.navigate(['/cart']);
    if (!this.isNavbarCollapsed) {
      this.toggleNavbar();
    }
    if (!this.isSearchBarCollapsed) {
      this.toggleSearchBar();
    }
  }

  openOrders() {
    this.router.navigate(['/order']);
  }

  openProfile() {
    this.router.navigate(['/profile']);
  }

  openWishlist() {
    this.router.navigate(['/wishlist']);
  }

  logout() {
    this.auth.logout();
    this.profile = null;
    this.router.navigate(['/home']);
    this.toggleNavbar();
  }

  onSearch(event: any) {
    this.router.navigate(['/catalog'], { queryParams: { search: this.searchQuery } });
    this.toggleSearchBar();
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value === '') {
      this.clearSearch();
    }
  }
  
  clearSearch() {
    this.searchQuery = '';
    this.router.navigate(['/catalog']);
    this.toggleSearchBar();
  }

}
